import { Component, OnInit } from '@angular/core';
import { LoadScriptsService } from 'src/app/core/globalFunctions/load-scripts.service';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DepartmentsService } from 'src/app/core/services/commonServices/departments.service';
import { StagesService } from 'src/app/core/services/commonServices/stages.service';
import { GradesService } from 'src/app/core/services/commonServices/grades.service';
import { DevisionsService } from 'src/app/core/services/commonServices/devisions.service';
import { keyValue } from 'src/app/core/models/keyValue';
import { ReligionsService } from 'src/app/core/services/commonServices/religions.service';
import { NationalitiesService } from 'src/app/core/services/commonServices/nationalities.service';
import { StudentApplyService } from 'src/app/core/services/student-apply.service';
import { nullFieldsValidator } from 'src/app/core/globalFunctions/validators/nullFieldsValidator';
import { devisionValidator } from './ControllerValidators/devisionValidator';
import { gardiansValidator } from './ControllerValidators/gardiansValidator';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/core/services/commonServices/language.Service';
import { SocialStatusService } from 'src/app/core/services/commonServices/socialStatus.service';
import { DeclarationEnrollmentService } from 'src/app/core/services/commonServices/DeclarationEnrollment.service';
import { ReadingSettingsDataService } from 'src/app/core/services/commonServices/reading-settings-data.service';
import { HttpClient } from '@angular/common/http';
import { log } from 'console';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { style } from '@angular/animations';


@Component({
  selector: 'app-apply-now',
  templateUrl: './apply-now.component.html',
  styleUrls: ['./apply-now.component.css']
})
export class ApplyNowComponent implements OnInit {

  constructor(private loadPageScripts: LoadScriptsService, private formBuilder: FormBuilder, private readingSettingsData: ReadingSettingsDataService,
    private departmentsService: DepartmentsService, private stagesService: StagesService, private gradesService: GradesService,
    private devisionsService: DevisionsService, private ReligionsService: ReligionsService, private nationalitiesService: NationalitiesService,
    private studentApply: StudentApplyService, private router: Router, private translate: TranslateService, private LanguageService: LanguageService,
    private SocialStatusService: SocialStatusService, private DeclarationEnrollmentService: DeclarationEnrollmentService) {
    loadPageScripts.applyNowScripts();
    this.fillDepartments();
    this.fillStages();
    this.fillNationalities();
    this.fillReligion();
    this.fillSecondLanguage();
    this.fillSocialStatus();
    this.fillDeclarationEnrollment();
    this.fillAllGrades();
  }
  doaminName: string = this.readingSettingsData.getSchoolDomain();

  departments: keyValue[] = [];
  stages: keyValue[] = [];
  grades: keyValue[] = [];
  devisions: keyValue[] = [];
  relegions: keyValue[] = [];
  nationalities: keyValue[] = [];
  languages: keyValue[] = [];
  socialStauts: keyValue[] = [];
  DeclarationEnrollment: string = '';
  PersonalImage: any;
  birthDateCertificate: any;
  fatherPassport: any;
  motherPassport: any;
  studentPassport: any;
  IsHaveBrothers: boolean = false;
  allgrades: keyValue[] = [];
  showPrint: boolean = false;
  isFormSubmited = false;
  ageValidation = true;
  PrintedData: any = {};
  ageTillOctober: any = {};

  formData = this.formBuilder.group({
    studentData: this.formBuilder.group({
      name: ['', Validators.required],
      nameEngilsh: ['', Validators.required],
      gender: ['male'],
      department: [null, nullFieldsValidator()],
      stage: [null, nullFieldsValidator()],
      grade: [null, nullFieldsValidator()],
      devision: [null, devisionValidator(this.devisions)],
      nationalId: [, Validators.required],
      birthDate: [, Validators.required],
      homeAddress: ['', Validators.required],
      phoneNumber: ['', Validators.required],
      nationality: [null, nullFieldsValidator()],
      religion: [null, nullFieldsValidator()],
      secondLanguage: [''],
      lastSchool: [''],
      relativesNumber: [''],
      stuPersonalImage: ['', nullFieldsValidator()],
      stuBirthDateCertificate: ['', nullFieldsValidator()],
      fatherPassport: ['', nullFieldsValidator()],
      motherPassport: ['', nullFieldsValidator()],
      studentPassport: ['', nullFieldsValidator()],
      IsHaveBrothers: [false],
      brotherslit: this.formBuilder.array([])

    }),
    fatherData: this.formBuilder.group({
      name: ['', Validators.required],
      nameEngilsh: [''],
      phoneNumber: ['', Validators.required],
      email: [''],
      nationalId: [, Validators.required],
      fathernationality: [''],
      fatherjob: [''],
      fatherCompany: [''],
      FatherQualification: ['']
    }),
    motherData: this.formBuilder.group({
      name: ['', Validators.required],
      nameEngilsh: [''],
      phoneNumber: ['', Validators.required],
      email: [''],
      nationalId: [, Validators.required],
      mothernationality: [''],
      motherjob: [''],
      motherSocialStatus: [''],
      legalGardian: [1],
      MotherQualification: ['']
    }),
    gardian: this.formBuilder.group({
      gardianType: ["father"],
      name: [''],
      nameEngilsh: [''],
      phoneNumber: [''],
      email: [''],
      nationalId: [''],
      relationWithStudent: [''],
      parentnationality: [''],
      parentjob: ['']
    }, { Validators: [gardiansValidator()] }),
    Enrollment: this.formBuilder.group({
      IsAcceptedEnrollment: [false, Validators.required],
    }),
    MedicalData: this.formBuilder.group({
      personName: [''],
      EmergencyMobile: [''],
      EmergencyEmail: [''],
      emergencyAddress: [''],
      previousMedicalStatus: [''],
      IsStudentTakeDrugs: ['no'],
      DrugsName: [''],
      SchoolResponsibilty: [''],
      FamilyDoctorRecommendations: [''],
      StudentDoActivities: ['yes'],
      StudentRetardationType: [1],
      studentOrder: [1],


    })


  }
  );


  get studentDatacontrols() {
    return this.formData.controls.studentData as FormGroup
  }

  get layerscontrols() {
    return this.studentDatacontrols.controls
  }

  get brotherlistcontrols(): FormArray {
    return this.layerscontrols.brotherslit as FormArray;
  }

  addlayer() {
    let empty: boolean = false
    const control = <FormArray>this.layerscontrols.brotherslit as FormArray
    if (control.controls.length > 0) {
      for (let index = 0; index < control.controls.length; index++) {
        if (control.controls[index].value.name == '' || control.controls[index].value.grad == '' || control.controls[index].value.grad == null) {
          empty = true;

          break
        }
      }
      if (!empty) {
        control.push(this.patchValues('', null))
      }

    }
    else {
      control.push(this.patchValues('', null))
    }

  }
  patchValues(name: any, grad: any) {
    return this.formBuilder.group({
      name: [name, Validators.required],
      grad: [grad, Validators.required],
    })

  }

  fillDepartments(): void {
    this.departmentsService.getDepartments().subscribe((data: any) => {
      this.departments = data;
      console.log(this.departments)
    });
  }

  fillStages(): void {
    this.stagesService.getStages().subscribe((data: any) => {
      this.stages = data;
    });;
  }

  fillGrades(stageid: any, depid: any): void {
    this.grades = [];
    if (stageid == null || depid == null || stageid == 'null' || depid == 'null') {
      return;
    }
    else {
      this.gradesService.getGrades(stageid, depid).subscribe((data: any) => {
        this.grades = data;
        this.filldevision(this.grades[0]?.key);
      });
    }
  }
  ValidateAge(birthdate: any, subphaseId: any, deptId: any) {
    if (birthdate == null || birthdate == 'null' || subphaseId == null || subphaseId == 'null' || deptId == null || deptId == 'null') {
      return;
    }
    this.gradesService.ValidateOctoberAge(birthdate, subphaseId, deptId).subscribe((data: any) => {
      console.log(data);
      var appended = ''
      $('#AgeInputs').empty();
      if (!data.Item1) {
        $('#BirthDateddl').css('border-color', 'red')
        appended = `<label style="width:15%" for="" class="label-tag">${this.translate.instant('year')}</label>
        <input type="number" style="border-color:red;width:15%" value="${data.Item2.year}" class="form-input" disabled>
          <label style="width:15%" for="" class="label-tag">${this.translate.instant('month')}</label>
          <input type="number" style="border-color:red;width:15%" value="${data.Item2.month}" class="form-input" disabled>
          <label style="width:15%" for="" class="label-tag">${this.translate.instant('day')}</label>
          <input type="number" style="border-color:red;width:15%" value="${data.Item2.day}" class="form-input" disabled>
          <label for="" style="color:red" class="label-tag">${this.translate.instant('studentDosentmatch')}</label>
          `;
        this.ageValidation = false;
      }
      else {
        $('#BirthDateddl').css('border-color', '');
        appended = `<label style="width:15%" for="" class="label-tag">${this.translate.instant('year')}</label>
        <input style="width:15%" type="number" value="${data.Item2.year}" class="form-input" disabled>
          <label style="width:15%" for="" class="label-tag">${this.translate.instant('month')}</label>
          <input style="width:15%" type="number" value="${data.Item2.month}" class="form-input" disabled>
          <label style="width:15%" for="" class="label-tag">${this.translate.instant('day')}</label>
          <input style="width:15%" type="number" value="${data.Item2.day}" class="form-input" disabled>
          `;
        this.ageValidation = true;
        this.ageTillOctober = data.Item2;
      }
      $('#AgeInputs').append(appended);
    });
  }
  fillAllGrades(): void {
    this.gradesService.getAllGrades().subscribe((data: any) => {
      this.allgrades = data;
    })

  }
  filldevision(gradeid: number): void {
    this.devisions = [];
    if (gradeid != null) {
      this.devisionsService.getDevisions(gradeid).subscribe((data: any) => {
        this.devisions = data;
      });
    }
  }
  fillReligion() {

    this.ReligionsService.getReligions().subscribe((data: any) => {
      this.relegions = data;
    })
  }
  fillNationalities() {
    this.nationalitiesService.getNationalities().subscribe((data: any) => {
      this.nationalities = data;
    })
  }
  fillSecondLanguage() {
    this.LanguageService.getLanguages().subscribe((data: any) => {
      this.languages = data;
    })
  }
  fillSocialStatus() {
    this.SocialStatusService.getSocialStatus().subscribe((data: any) => {
      this.socialStauts = data;
    })

  }
  fillDeclarationEnrollment() {
    this.DeclarationEnrollmentService.getDeclarationEnrollment().subscribe((data: any) => {
      this.DeclarationEnrollment = data == null ? "" : data.text;

    })
  }
  stageChangeEvent() {
    this.fillGrades(this.formData.get(['studentData', 'stage'])?.value, this.formData.get(['studentData', 'department'])?.value);
  }
  ValidateAgePerOctober() {
    this.ValidateAge(this.formData.get(['studentData', 'birthDate'])?.value, this.formData.get(['studentData', 'grade'])?.value, this.formData.get(['studentData', 'department'])?.value);
  }
  gradeChangeEvent() {
    this.filldevision(this.formData.get(['studentData', 'devision'])?.value);
  }
  handleFileInput(event: any, type: any) {

    if (event.target.files.length > 0) {
      if (type == "stuPersonalImage") {
        this.PersonalImage = event.target.files[0];
      }
      else if (type == "birthdate") {
        this.birthDateCertificate = event.target.files[0];
      }
      else if (type == "studentPassport") {
        this.studentPassport = event.target.files[0];
      }
      else if (type == "fatherPassport") {
        this.fatherPassport = event.target.files[0];
      }
      else {
        this.motherPassport = event.target.files[0];
      }
    }
  }
  handleBrothers(event: any) {
    this.IsHaveBrothers = event.target.checked;


  }
  printData() {
  }
  async sendData() {
    try {

    var flag = false;
    this.isFormSubmited = true;
    if (!this.formData.valid || !this.ageValidation) {
      Swal.fire(this.translate.instant('payAttention'), this.translate.instant('dataRequired'), 'warning');
      return;
    }
    await this.studentApply.sendData(this.formData, this.birthDateCertificate, this.motherPassport, this.fatherPassport, this.studentPassport, this.PersonalImage).pipe().toPromise().then(
      (data: any) => {
        console.log(data.body)
        this.PrintedData = data.body;
        // this.PrintedData.studentBirthDate = $('BirthDateddl').val()
        this.isFormSubmited = false;
        flag = true;
        setTimeout(() => {
        this.loadingData(flag);
        }, 1000);
      }, (err: any) => {
        console.log(err);
        Swal.fire(this.translate.instant('payAttention'), this.translate.instant(err.error), 'warning');
        this.reload();
      });
            
    } catch (error1) {
      Swal.fire(this.translate.instant('payAttention'), this.translate.instant('ErrorPleaseTryAgian'), 'warning');
    }
  }

  reload() {
    let currentUrl = this.router.url;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([currentUrl]);
  }
  loadingData(flag: boolean) {
    debugger;
    if (flag) {
      if (this.PrintedData.StuPersonalImagePath != "") {
        $('#TableImage').attr('src', this.doaminName + this.PrintedData.StuPersonalImagePath);
        $('#TableImage').removeAttr('hidden');
      }
      console.log($("#resDetails").html());
      var dvReport = $("#resDetails").html();
      var myWindow: any = window.open("myWindow", "newwin")
      myWindow.document.write('<html> <head>  <style>table, td, th { border: 1px solid #ddd; text-align: left;} table {border-collapse: collapse; width: 100%;} th, td {padding: 0px;text-align:center}</style>   <title></title>');
      myWindow.document.write('</head><body dir="rtl" >');
      myWindow.document.write(dvReport);
      myWindow.document.write('</body></html>');
      myWindow.document.getElementById('resDetails').removeAttribute('hidden');
      setTimeout(() => {
        myWindow.print();
        myWindow.close();
        this.reload();
      }, 1000);
      Swal.fire(this.translate.instant('goodJob'), this.translate.instant('appliedsuccessfully'), 'success');
    }


  }
  SearchByNationalId() {
    if ($('#NationalIdTxt').val()!='') {
      $('#NationalIdTxt').removeAttr('style');
      this.studentApply.SearchByNationalId($('#NationalIdTxt').val()).subscribe((res:any)=>{
        console.log(res);
        if (res.res) {
          $('#notfounRes').removeAttr('hidden');
          $('#foundRes').attr('hidden','hidden');
        }else{
          $('#notfounRes').attr('hidden','hidden');
          $('#foundRes').removeAttr('hidden');
          res.StudentApplyStatus == 'Accepted'? $('#foundRes').addClass('bg-success'):
          res.StudentApplyStatus == 'Rejected'?$('#foundRes').addClass('bg-danger'):
          $('#foundRes').addClass('bg-secondary');
          $('#StdNameh2').html(this.translate.instant('StudentName')+' : '+ res.studentName);
          $('#RequstStat').html(this.translate.instant('RequestStatus')+' : '+ this.translate.instant(res.StudentApplyStatus));
        }
      },(err:any)=>{
        console.log(err);
      })
    }else{
      $('#NationalIdTxt').attr('style','border-color:red');
    }

  }
  Register(){
    $('#SearchByNationalId').attr('hidden','hidden');
    $('#ApplyForm').removeAttr('hidden');
  }
  ngOnInit(): void {

  }

}
