<div class="Content2Column">
  <h1>{{aboutData.title}}</h1>
  <div class="desc-sec">
    <img class="img-about" src="{{domainUrl+aboutData.imgUrl}}" alt="">
    <div class="desc-div">
      <p>{{aboutData.text}}</p>
    </div>
  </div>
</div>


<!--side menue-->
<div class="ColumnLeft">
  <nav id="SideHeadingPages" class="SideHeading">
    <h2>{{'RelatedPages'|translate}}</h2>
    <ul class="SideList">
      <li><a style="text-align: center;" routerLink="" id="nav1125">{{'home'|translate}}</a></li>
      <li><a style="text-align: center;" routerLink="/about" id="nav1126">{{'about'|translate}}</a></li>
      <li><a style="text-align: center;" routerLink="/news" id="nav1127">{{'news'|translate}}</a> </li>
      <li><a style="text-align: center;" routerLink="/events" id="nav1127">{{'events'|translate}}</a> </li>
      <li><a style="text-align: center;" routerLink="/apply" id="nav1127">{{'applynow'|translate}}</a> </li>
      <!-- <li><a style="text-align: center;" routerLink="/jobs" id="nav1127">{{'Jobs'|translate}}</a> </li> -->
    </ul>
  </nav>
</div>
