<div class="Content2Column">
    <h1>{{ 'Jobs' | translate }}</h1>
    <table class="table table-bordered table-hover" id="AppTable">
        <thead>
            <tr>
                <th>{{ 'JobTitle' | translate }}</th>
                <th>{{ 'WorkPlace' | translate }}</th>
                <th>{{ 'NeededNumber' | translate }}</th>
                <th>{{ 'QualificationRequired' | translate }}</th>
                <th>{{ 'LastDateApplying' | translate }}</th>
                <th>{{ 'ExYearsCount' | translate }}</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            
        </tbody>
    </table>
</div>