<div style="margin-right: 25%;" class="w-50 text-center" id="SearchByNationalId">
  <h1>{{ 'searchByNationalId' | translate }}</h1>
  <form>
    <div class="form-group">
      <input type="number" class="form-control" id="NationalIdTxt"
        placeholder="{{'PleaseEnterStdNationalId'|translate}}">
    </div>
    <button type="button" (click)="SearchByNationalId()" class="btn btn-primary m-1">{{'search'|translate}}</button>
    <button type="button" (click)="Register()" class="btn btn-secondary m-1">{{'ApplyNow'|translate}}</button>
  </form>
  <div hidden class="card m-3 p-3" id="notfounRes">
    <h2>{{'StudentNotFound'|translate}} <span><button type="button" (click)="Register()"
          class="btn-link">{{'Fromhere'|translate}}</button></span></h2>
  </div>
  <div hidden class="card m-3 p-3" id="foundRes">
    <h2 id="StdNameh2"></h2>
    <h2 id="RequstStat"></h2>
  </div>
</div>


<form id="ApplyForm" hidden [formGroup]="formData">
  <div class="ContentColumn">
    <div action="" class="form-sec new-style-form">
      <div class="step-app step-card" id="demo">
        <ul class="step-steps sbs sbs--border">
          <!--student-->
          <li data-step-target="step1">
            <div class="step">
              <span class="indicator">1</span>
              <span class="indicator indicator2"><i class="fa fa-check"></i></span>
              <span class="description">{{'student'|translate}}</span>
            </div>
          </li>
          <!--father-->
          <li data-step-target="step2">
            <div class="step">
              <span class="indicator">2</span>
              <span class="indicator indicator2"><i class="fa fa-check"></i></span>
              <span class="description">{{'father'|translate}}</span>
            </div>
          </li>
          <!--mother-->
          <li data-step-target="step3">
            <div class="step">
              <span class="indicator">3</span>
              <span class="indicator indicator2"><i class="fa fa-check"></i></span>
              <span class="description">{{'mother'|translate}}</span>
            </div>
          </li>
          <!--gaurdian-->
          <li data-step-target="step3">
            <div class="step">
              <span class="indicator">4</span>
              <span class="indicator indicator2"><i class="fa fa-check"></i></span>
              <span class="description">{{'gardian'|translate}}</span>
            </div>
          </li>
          <!--Medical-->
          <li data-step-target="step5">
            <div class="step">
              <span class="indicator">5</span>
              <span class="indicator indicator2"><i class="fa fa-check"></i></span>
              <span class="description">{{'MedicalData'|translate}}</span>
            </div>
          </li>
          <!--Declaration Enrollment-->
          <li data-step-target="step6">
            <div class="step">
              <span class="indicator">6</span>
              <span class="indicator indicator2"><i class="fa fa-check"></i></span>
              <span class="description">{{'Enrollment'|translate}}</span>
            </div>
          </li>
          <!-- <li data-step-target="step3">
          <div class="step">
            <span class="indicator">5</span>
            <span class="indicator indicator2"><i class="fa fa-check"></i></span>
            <span class="description">More Details</span>
          </div>
        </li>-->
        </ul>
        <div class="step-content">
          <!--student form-->

          <div formGroupName="studentData" class="step-tab-panel w3-animate-right" data-step="step1">
            <div class="apply-form">
              <h5 class="head-form">{{'student'|translate}}</h5>
              <div class="form-content">
                <ul class="ul-form">
                  <li>
                    <label for="" class="label-tag">{{'studentNameArabic'|translate}}</label>
                    <input
                      [ngClass]="((formData.get(['studentData', 'name'])?.touched||this.isFormSubmited)&&formData.get(['studentData', 'name'])?.errors?.required)?'invalid-validation':''"
                      formControlName="name" type="text" class="form-input">
                  </li>
                  <li>
                    <label for="" class="label-tag">{{'StudentNameEnglish'|translate}}</label>
                    <input
                      [ngClass]="((formData.get(['studentData', 'nameEngilsh'])?.touched||this.isFormSubmited)&&formData.get(['studentData', 'nameEngilsh'])?.errors?.required)?'invalid-validation':''"
                      formControlName="nameEngilsh" type="text" class="form-input">

                  </li>
                  <li>
                    <label for="" class="label-tag">{{'Gender'|translate}}</label>
                    <select formControlName="gender" name="" id="" class="form-select">
                      <option value="male">{{'male'|translate}}</option>
                      <option value="female">{{'female'|translate}}</option>
                    </select>
                  </li>
                  <li>
                    <label for="" class="label-tag">{{'department'|translate}}</label>
                    <select
                      [ngClass]="((formData.get(['studentData', 'department'])?.touched||this.isFormSubmited)&&formData.get(['studentData', 'department'])?.value==null)?'invalid-validation':''"
                      (ngModelChange)="stageChangeEvent()" formControlName="department" name="" id=""
                      class="form-select">
                      <option value="null">{{'selectAdepartment'|translate}}</option>
                      <option *ngFor="let department of departments" [value]="department.key">
                        {{department.value}}
                      </option>
                    </select>
                  </li>
                  <li>
                    <label for="" class="label-tag">{{'Stage'|translate}}</label>
                    <select
                      [ngClass]="((formData.get(['studentData', 'stage'])?.touched||this.isFormSubmited)&&formData.get(['studentData', 'stage'])?.errors?.isNull)?'invalid-validation':''"
                      (ngModelChange)="stageChangeEvent()" formControlName="stage" name="" id="" class="form-select">
                      <option value="null">{{'selectAstage'|translate}}</option>
                      <option *ngFor="let stage of stages" [value]="stage.key">{{stage.value}}
                      </option>
                    </select>

                  </li>
                  <li>
                    <label for="" class="label-tag">{{'Grade'|translate}}</label>
                    <select
                      [ngClass]="((formData.get(['studentData', 'grade'])?.touched||this.isFormSubmited)&&formData.get(['studentData', 'grade'])?.value==null)?'invalid-validation':''"
                      (ngModelChange)="gradeChangeEvent()" formControlName="grade" id="" class="form-select">
                      <option value="null">{{'selectAgrade'|translate}}</option>
                      <option *ngFor="let grade of grades" [value]="grade.key">{{grade.value}}
                      </option>
                    </select>

                  </li>
                  <li>
                    <label for="" class="label-tag">{{'Devision'|translate}}</label>
                    <select
                      [ngClass]="((formData.get(['studentData', 'devision'])?.touched||this.isFormSubmited)&&formData.get(['studentData', 'devision'])?.errors?.isNull)?'invalid-validation':''"
                      formControlName="devision" id="" class="form-select">
                      <option value="null">{{'selectAdevision'|translate}}</option>
                      <option *ngFor="let devision of devisions" [value]="devision.key">{{devision.value}}
                      </option>
                    </select>

                  </li>
                  <li>
                    <label for="" class="label-tag">{{'NationalId'|translate}}</label>
                    <input
                      [ngClass]="((formData.get(['studentData', 'nationalId'])?.touched||this.isFormSubmited)&&formData.get(['studentData', 'nationalId'])?.errors?.required)?'invalid-validation':''"
                      formControlName="nationalId" type="number" class="form-input">

                  </li>
                  <li>
                    <label for="" class="label-tag">{{'DateOfBirth'|translate}}</label>
                    <input (ngModelChange)="ValidateAgePerOctober()" id="BirthDateddl"
                      [ngClass]="((formData.get(['studentData', 'birthDate'])?.touched||this.isFormSubmited)&&formData.get(['studentData', 'birthDate'])?.errors?.required)?'invalid-validation':''"
                      formControlName="birthDate" type="date" class="form-input">

                  </li>
                  <li style="padding-top: 5.5%;" id="AgeInputs">

                  </li>
                  <li>
                    <label for="" class="label-tag">{{'Religion'|translate}}</label>
                    <select
                      [ngClass]="((formData.get(['studentData', 'religion'])?.touched||this.isFormSubmited)&&formData.get(['studentData', 'religion'])?.value==null)?'invalid-validation':''"
                      formControlName="religion" name="" id="" class="form-select">
                      <option value="null">{{'selectAreligion'|translate}}</option>
                      <option *ngFor="let religion of relegions" [value]="religion.key">{{religion.value}}
                      </option>
                    </select>

                  </li>
                  <li>
                    <label for="" class="label-tag">{{'HomeAddress'|translate}}</label>
                    <input
                      [ngClass]="((formData.get(['studentData', 'homeAddress'])?.touched||this.isFormSubmited)&&formData.get(['studentData', 'homeAddress'])?.errors?.required)?'invalid-validation':''"
                      formControlName="homeAddress" type="text" class="form-input">

                  </li>
                  <li>
                    <label for="" class="label-tag">{{'PhoneNumber'|translate}}</label>
                    <input
                      [ngClass]="((formData.get(['studentData', 'phoneNumber'])?.touched||this.isFormSubmited)&&formData.get(['studentData', 'phoneNumber'])?.errors?.required)?'invalid-validation':''"
                      formControlName="phoneNumber" type="number" class="form-input">

                  </li>
                  <li>
                    <label for="" class="label-tag">{{'Nationality'|translate}}</label>
                    <select
                      [ngClass]="((formData.get(['studentData', 'nationality'])?.touched||this.isFormSubmited)&&formData.get(['studentData', 'nationality'])?.value==null)?'invalid-validation':''"
                      formControlName="nationality" name="" id="" class="form-select">
                      <option value="null">{{'selectAnationality'|translate}}</option>
                      <option *ngFor="let nationality of nationalities" [value]="nationality.key">{{nationality.value}}
                      </option>
                    </select>

                  </li>
                  <li>
                    <label for="" class="label-tag">{{'SecondLanguage'|translate}}</label>
                    <select formControlName="secondLanguage" name="" id="" class="form-select">
                      <option value="null">{{'selectALanguage'|translate}}</option>
                      <option *ngFor="let language of languages" [value]="language.key">{{language.value}}
                      </option>
                    </select>

                  </li>
                  <li>
                    <label for="" class="label-tag">{{'lastSchool'|translate}}</label>
                    <input
                      [ngClass]="((formData.get(['studentData', 'lastSchool'])?.touched||this.isFormSubmited)&&formData.get(['studentData', 'lastSchool'])?.errors?.required)?'invalid-validation':''"
                      formControlName="lastSchool" type="text" class="form-input">

                  </li>
                  <li>
                    <label for="" class="label-tag">{{'relativesNumber'|translate}}</label>
                    <input
                      [ngClass]="((formData.get(['studentData', 'relativesNumber'])?.touched||this.isFormSubmited)&&formData.get(['studentData', 'relativesNumber'])?.errors?.required)?'invalid-validation':''"
                      formControlName="relativesNumber" type="number" class="form-input">

                  </li>
                  <li>

                  </li>
                  <li>

                    <button class="btn" (click)="addlayer()"><img style="width:20px"
                        src="../assets/img/icons/plus.png"></button>
                    <span class="">{{'IsHaveBrothers'|translate}}</span>
                    <div formArrayName="brotherslit">
                      <div *ngFor="let layer of brotherlistcontrols.controls ;let i = index">
                        <div [formGroupName]="i">
                          <ul class="ul-form">

                            <li> <label class="">{{'Grade'|translate}}</label>
                              <select formControlName="grad" id="" class="form-select">
                                <option value="null">{{'selectAgrade'|translate}}</option>
                                <option *ngFor="let grade of allgrades" [value]="grade.key">{{grade.value}}
                                </option>
                              </select>
                            </li>
                            <li> <label class="">{{'brotherName'|translate}}</label>
                              <input type="text" class="form-control" formControlName="name">
                            </li>
                          </ul>

                        </div>
                      </div>
                    </div>
                    <!-- <label class="normalWidthLabel">     

                    < <input type="checkbox"
                    formControlName="IsHaveBrothers" (change)="handleBrothers($event)" value="IsHaveBrothers" id=""
                      name="IsHaveBrothers">
                      <span class="spanCheck">{{'IsHaveBrothers'|translate}}</span>
                      <button><img src="~/assets/img/icons/plus.png"></button>

                  </label> -->

                  </li>
                  <li></li>
                  <li *ngIf="IsHaveBrothers">
                    <label for="" class="label-tag">{{'firstBrother'|translate}}</label>
                    <input formControlName="" type="text" class="form-input">
                  </li>
                  <li *ngIf="IsHaveBrothers">

                  </li>
                  <li>
                    <h3>{{'attachments'|translate}}</h3>
                  </li>
                  <li></li>
                  <li>
                    <label for="" class="label-tag">{{'stuPersonalImage'|translate}}</label>
                    <input formControlName="stuPersonalImage" (change)="handleFileInput($event,'stuPersonalImage')"
                      type="file" accept="image/*" class="form-input">
                  </li>
                  <li>
                    <label for="" class="label-tag">{{'stuBirthDateCertificate'|translate}}</label>
                    <input formControlName="stuBirthDateCertificate" (change)="handleFileInput($event,'birthdate')"
                      type="file" class="form-input">
                  </li>
                  <li>
                    <label for="" class="label-tag">{{'motherPassport'|translate}}</label>
                    <input formControlName="motherPassport" type="file"
                      (change)="handleFileInput($event,'motherPassport')" class="form-input">
                  </li>
                  <li>
                    <label for="" class="label-tag">{{'fatherPassport'|translate}}</label>
                    <input formControlName="fatherPassport" type="file"
                      (change)="handleFileInput($event,'fatherPassport')" class="form-input">
                  </li>
                  <li>
                    <label for="" class="label-tag">{{'studentPassport'|translate}}</label>
                    <input formControlName="studentPassport" type="file"
                      (change)="handleFileInput($event,'studentPassport')" class="form-input">
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <!--father form-->
          <div formGroupName="fatherData" class="step-tab-panel w3-animate-right" data-step="step2">
            <div class="apply-form">
              <h5 class="head-form">{{'father'|translate}}</h5>
              <div class="form-content">
                <ul class="ul-form">
                  <li>
                    <label for="" class="label-tag">{{'FullNameArabic'|translate}}</label>
                    <input
                      [ngClass]="((formData.get(['fatherData', 'name'])?.touched||this.isFormSubmited)&&formData.get(['fatherData', 'name'])?.errors?.required)?'invalid-validation':''"
                      formControlName="name" type="text" class="form-input">

                  </li>
                  <li>
                    <label for="" class="label-tag">{{'FullNameEnglish'|translate}}</label>
                    <input formControlName="nameEngilsh" type="text" class="form-input">
                  </li>
                  <li>
                    <label for="" class="label-tag">{{'NationalId'|translate}}</label>
                    <input
                      [ngClass]="((formData.get(['fatherData', 'nationalId'])?.touched||this.isFormSubmited)&&formData.get(['fatherData', 'nationalId'])?.errors?.required)?'invalid-validation':''"
                      type="number" formControlName="nationalId" class="form-input">

                  </li>
                  <li>
                    <label for="" class="label-tag">{{'Email'|translate}}</label>
                    <input type="email" formControlName="email" class="form-input">
                  </li>
                  <li>
                    <label for="" class="label-tag">{{'PhoneNumber'|translate}}</label>
                    <input
                      [ngClass]="((formData.get(['fatherData', 'phoneNumber'])?.touched||this.isFormSubmited)&&formData.get(['fatherData', 'phoneNumber'])?.errors?.required)?'invalid-validation':''"
                      type="text" formControlName="phoneNumber" class="form-input">

                  </li>
                  <li>
                    <label for="" class="label-tag">{{'Nationality'|translate}}</label>
                    <select formControlName="fathernationality" name="" id="" class="form-select">
                      <option value="null">{{'selectAnationality'|translate}}</option>
                      <option *ngFor="let nationality of nationalities" [value]="nationality.key">{{nationality.value}}
                      </option>
                    </select>

                  </li>
                  <li>
                    <label for="" class="label-tag">{{'job'|translate}}</label>
                    <input formControlName="fatherjob" type="text" class="form-input">
                  </li>
                  <li>
                    <label for="" class="label-tag">{{'fatherCompany'|translate}}</label>
                    <input formControlName="fatherCompany" type="text" class="form-input">
                  </li>
                  <li>
                    <label for="" class="label-tag">{{'FatherQualification'|translate}}</label>
                    <input formControlName="FatherQualification" type="text" class="form-input">
                  </li>
                  <li>

                  </li>
                </ul>
              </div>
            </div>
          </div>
          <!--mother form-->
          <div formGroupName="motherData" class="step-tab-panel w3-animate-right" data-step="step3">
            <div class="apply-form">
              <h5 class="head-form">{{'mother'|translate}}</h5>
              <div class="form-content">
                <ul class="ul-form">
                  <li>
                    <label for="" class="label-tag">{{'FullNameArabic'|translate}}</label>
                    <input
                      [ngClass]="((formData.get(['motherData', 'name'])?.touched||this.isFormSubmited)&&formData.get(['motherData', 'name'])?.errors?.required)?'invalid-validation':''"
                      type="text" formControlName="name" class="form-input">
                  </li>
                  <li>
                    <label for="" class="label-tag">{{'FullNameEnglish'|translate}}</label>
                    <input type="text" formControlName="nameEngilsh" class="form-input">
                  </li>
                  <li>
                    <label for="" class="label-tag">{{'NationalId'|translate}}</label>
                    <input
                      [ngClass]="((formData.get(['motherData', 'nationalId'])?.touched||this.isFormSubmited)&&formData.get(['motherData', 'nationalId'])?.errors?.required)?'invalid-validation':''"
                      type="number" formControlName="nationalId" class="form-input">

                  </li>
                  <li>
                    <label for="" class="label-tag">{{'Email'|translate}}</label>
                    <input formControlName="email" type="email" class="form-input">
                  </li>
                  <li>
                    <label for="" class="label-tag">{{'PhoneNumber'|translate}}</label>
                    <input
                      [ngClass]="((formData.get(['motherData', 'phoneNumber'])?.touched||this.isFormSubmited)&&formData.get(['motherData', 'phoneNumber'])?.errors?.required)?'invalid-validation':''"
                      type="text" formControlName="phoneNumber" class="form-input">

                  </li>
                  <li>
                    <label for="" class="label-tag">{{'Nationality'|translate}}</label>
                    <select formControlName="mothernationality" name="" id="" class="form-select">
                      <option value="null">{{'selectAnationality'|translate}}</option>
                      <option *ngFor="let nationality of nationalities" [value]="nationality.key">{{nationality.value}}
                      </option>
                    </select>

                  </li>
                  <li>
                    <label for="" class="label-tag">{{'job'|translate}}</label>
                    <input formControlName="motherjob" type="text" class="form-input">
                  </li>
                  <li>
                    <label for="" class="label-tag">{{'SocialStatus'|translate}}</label>
                    <select formControlName="motherSocialStatus" name="" id="" class="form-select">
                      <option value="null">{{'selectASocialStatus'|translate}}</option>
                      <option *ngFor="let status of socialStauts" [value]="status.key">{{status.value}}
                      </option>
                    </select>

                  </li>
                  <li>
                    <label for="" class="label-tag">{{'legalGardian'|translate}}</label>
                    <select formControlName="legalGardian" name="" id="" class="form-select">
                      <option value="1">{{'none'|translate}}</option>
                      <option value="2">{{'mother'|translate}}</option>
                      <option value="3">{{'father'|translate}}</option>
                    </select>
                  </li>
                  <li>
                    <label for="" class="label-tag">{{'MotherQualification'|translate}}</label>
                    <input formControlName="MotherQualification" type="text" class="form-input">
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <!--guardian form-->
          <div formGroupName="gardian" class="step-tab-panel w3-animate-right" data-step="step4">
            <div class="select-guardian">
              <span>{{'GuardianIs'|translate}}</span>
              <ul>
                <li>
                  <label for="group1">
                    <img src="assets/img/icons/1.png" alt="">
                    <em>{{'father'|translate}}</em>
                    <input type="radio" formControlName="gardianType" checked value="father" id="group1"
                      name="gardianType">
                  </label>

                </li>
                <li>
                  <label for="group2">
                    <img src="assets/img/icons/2.png" alt="">
                    <em>{{'mother'|translate}}</em>
                    <input type="radio" formControlName="gardianType" id="group2" value="mother" name="gardianType">
                  </label>
                </li>
                <li>
                  <label for="group3">
                    <img src="assets/img/icons/3.png" alt="">
                    <em>{{'other'|translate}}</em>
                    <input type="radio" formControlName="gardianType" id="group3" value="other" name="gardianType">
                  </label>
                </li>
              </ul>
            </div>
            <div class="apply-form gardianForm"
              [ngStyle]="{display:formData.get(['gardian', 'gardianType'])?.value==='other'? 'block': 'none'} ">
              <h5 class="head-form">{{'other'|translate}}</h5>
              <div class="form-content">
                <ul class="ul-form">
                  <li>
                    <label for="" class="label-tag">{{'FullNameArabic'|translate}}</label>
                    <input
                      [ngClass]="((formData.get(['gardian', 'name'])?.touched||this.isFormSubmited)&&formData.get(['gardian', 'gardianType'])?.value==='other'&&formData.get(['gardian', 'name'])?.value==='')?'invalid-validation':''"
                      formControlName="name" type="text" class="form-input">
                  </li>
                  <li>
                    <label for="" class="label-tag">{{'FullNameEnglish'|translate}}</label>
                    <input formControlName="nameEngilsh" type="text" class="form-input">
                  </li>
                  <li>
                    <label for="" class="label-tag">{{'RelationShipWithStudent'|translate}}</label>
                    <input
                      [ngClass]="((formData.get(['gardian', 'relationWithStudent'])?.touched||this.isFormSubmited)&&formData.get(['gardian', 'gardianType'])?.value==='other'&&formData.get(['gardian', 'relationWithStudent'])?.value==='')?'invalid-validation':''"
                      formControlName="relationWithStudent" type="text" class="form-input">
                  </li>
                  <li>
                    <label for="" class="label-tag">{{'nationalId'|translate}}</label>
                    <input
                      [ngClass]="((formData.get(['gardian', 'nationalId'])?.touched||this.isFormSubmited)&&formData.get(['gardian', 'gardianType'])?.value==='other'&&formData.get(['gardian', 'nationalId'])?.value==='')?'invalid-validation':''"
                      formControlName="nationalId" type="number" class="form-input">
                  </li>
                  <li>
                    <label for="" class="label-tag">{{'Email'|translate}}</label>
                    <input formControlName="email" type="email" class="form-input">
                  </li>
                  <li>
                    <label for="" class="label-tag">{{'PhoneNumber'|translate}}</label>
                    <input
                      [ngClass]="((formData.get(['gardian', 'phoneNumber'])?.touched||this.isFormSubmited)&&formData.get(['gardian', 'gardianType'])?.value==='other'&&formData.get(['gardian', 'phoneNumber'])?.value==='')?'invalid-validation':''"
                      formControlName="phoneNumber" type="text" class="form-input">

                  </li>
                  <li>
                    <label for="" class="label-tag">{{'Nationality'|translate}}</label>
                    <select formControlName="parentnationality" name="" id="" class="form-select">
                      <option value="null">{{'selectAnationality'|translate}}</option>
                      <option *ngFor="let nationality of nationalities" [value]="nationality.key">{{nationality.value}}
                      </option>
                    </select>

                  </li>
                  <li>
                    <label for="" class="label-tag">{{'job'|translate}}</label>
                    <input formControlName="parentjob" type="text" class="form-input">
                  </li>

                </ul>
              </div>
            </div>
          </div>
          <!--Medical form-->
          <div formGroupName="MedicalData" class="step-tab-panel w3-animate-right" data-step="step5">
            <div class="apply-form">
              <h5 class="head-form">{{'MedicalData'|translate}}</h5>
              <div class="form-content">
                <ul class="ul-form">

                  <li>
                    <h3>{{'emergencyData'|translate}}</h3>
                  </li>
                  <li></li>
                  <li>
                    <label for="" class="label-tag">{{'personName'|translate}}</label>
                    <input type="text" formControlName="personName" class="form-input">
                  </li>
                  <li>
                    <label for="" class="label-tag">{{'EmergencyMobile'|translate}}</label>
                    <input type="text" formControlName="EmergencyMobile" class="form-input">
                  </li>

                  <li>
                    <label for="" class="label-tag">{{'EmergencyEmail'|translate}}</label>
                    <input formControlName="EmergencyEmail" type="email" class="form-input">
                  </li>
                  <li>
                    <label for="" class="label-tag">{{'Address'|translate}}</label>
                    <input type="text" formControlName="emergencyAddress" class="form-input">

                  </li>

                  <li>
                    <h3>{{'Medical'|translate}}</h3>
                  </li>
                  <li></li>
                  <li>
                    <label for="" class="label-tag">{{'previousMedicalStatus'|translate}}</label>
                    <input formControlName="previousMedicalStatus" type="text" class="form-input">
                  </li>
                  <li></li>
                  <li>
                    <label for="" class="label-tag">{{'DrugsName'|translate}}</label>
                    <input formControlName="DrugsName" type="text" class="form-input">
                  </li>
                  <li>
                    <div class="select-medical">
                      <span>{{'IsStudentTakeDrugs'|translate}}</span>
                      <ul>
                        <li>
                          <label for="yes">
                            <em>{{'yes'|translate}}</em>
                            <input type="radio" formControlName="IsStudentTakeDrugs" checked value="yes" id=""
                              name="IsStudentTakeDrugs">
                          </label>

                        </li>
                        <li>
                          <label for="no">
                            <em>{{'no'|translate}}</em>
                            <input type="radio" formControlName="IsStudentTakeDrugs" value="no"
                              name="IsStudentTakeDrugs">
                          </label>
                        </li>


                      </ul>
                    </div>

                  </li>
                  <li>
                    <label for="" class="label-tag">{{'SchoolResponsibilty'|translate}}</label>
                    <input formControlName="SchoolResponsibilty" type="text" class="form-input">
                  </li>
                  <li>
                    <label for="" class="label-tag">{{'FamilyDoctorRecommendations'|translate}}</label>
                    <input formControlName="FamilyDoctorRecommendations" type="text" class="form-input">
                  </li>
                  <li>
                    <div class="select-medical">
                      <span>{{'StudentDoActivities'|translate}}</span>
                      <ul>
                        <li>
                          <label for="group1">
                            <em>{{'yes'|translate}}</em>
                            <input type="radio" formControlName="StudentDoActivities" checked value="yes"
                              name="StudentDoActivities">
                          </label>

                        </li>
                        <li>
                          <label for="group2">
                            <em>{{'no'|translate}}</em>
                            <input type="radio" formControlName="StudentDoActivities" value="no"
                              name="StudentDoActivities">
                          </label>
                        </li>

                      </ul>
                    </div>

                  </li>
                  <li></li>
                  <li>
                    <label for="" class="label-tag">{{'StudentRetardationType'|translate}}</label>
                    <select formControlName="StudentRetardationType" name="" id="" class="form-select">
                      <option value="1">{{'none'|translate}}</option>
                      <option value="2">{{'hearing'|translate}}</option>
                      <option value="3">{{'vision'|translate}}</option>
                      <option value="4">{{'speaking'|translate}}</option>
                      <option value="5">{{'writing'|translate}}</option>

                    </select>
                  </li>
                  <li>
                    <label for="" class="label-tag">{{'studentOrder'|translate}}</label>
                    <select formControlName="studentOrder" name="" id="" class="form-select">
                      <option value="1">{{'oldest'|translate}}</option>
                      <option value="2">{{'middle'|translate}}</option>
                      <option value="3">{{'third'|translate}}</option>
                      <option value="4">{{'forth'|translate}}</option>
                      <option value="5">{{'fifth'|translate}}</option>

                    </select>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <!--Declaration Enrollment form-->
          <div formGroupName="Enrollment" class="step-tab-panel w3-animate-right" data-step="step6">
            <div class="select-guardian">
              <span>{{'Enrollment'|translate}}</span>
              <ul>
                <li>
                  <label for="group1">
                    <p>
                      {{DeclarationEnrollment}}
                    </p>
                    <em>{{'acceptEnrollment'|translate}}</em>
                    <input type="checkbox"
                      [ngClass]="((formData.get(['Enrollment', 'IsAcceptedEnrollment'])?.touched||this.isFormSubmited)&&formData.get(['Enrollment', 'IsAcceptedEnrollment'])?.errors?.required)?'invalid-validation':''"
                      formControlName="IsAcceptedEnrollment" value="IsAcceptedEnrollment" id=""
                      name="IsAcceptedEnrollment">
                  </label>

                </li>

              </ul>
            </div>

          </div>

          <!-- <div class="step-tab-panel w3-animate-right" data-step="step5">
          <div class="box-carousel">
            <div class="owl-carousel owl-theme info-carousel">
              <div class="item">
                <h3 class="head-qus">Please Upload Photo:</h3>
                <div class="content-info">
                  <div class="file-upload">
                    <input type="file" class="filepond">
                  </div>
                </div>
              </div>
              <div class="item">
                <h3 class="head-qus">Father's Occupation:</h3>
                <div class="content-info">
                  <div class="input-div">
                    <input type="text">
                    <span class="placeholder">Company / Organization
                      Name</span>
                  </div>
                  <div class="input-div">
                    <select>
                      <option value="">Test</option>
                      <option value="">Test</option>
                      <option value="">Test</option>
                      <option value="">Test</option>
                    </select>
                    <span class="placeholder"></span>
                  </div>

                </div>
              </div>
              <div class="item">
                <h3 class="head-qus">Father's Employment Type:</h3>
                <div class="content-info">
                  <ul>
                    <li class="inputGroup">
                      <input id="check20" name="check" type="checkbox">
                      <label for="check20">Employed in private
                        sector</label>
                    </li>
                    <li class="inputGroup">
                      <input id="check21" name="check" type="checkbox">
                      <label for="check21">Employed in governmental
                        work</label>
                    </li>
                    <li class="inputGroup">
                      <input id="check22" name="check" type="checkbox">
                      <label for="check22">Self-Employed</label>
                    </li>
                    <li class="inputGroup">
                      <input id="check23" name="check" type="checkbox">
                      <label for="check23">Retired</label>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="item">
                <h3 class="head-qus">Marital Status of Parents:</h3>
                <div class="content-info">
                  <ul>
                    <li class="inputGroup">
                      <input id="radio23" name="radio11" type="radio">
                      <label for="radio23">Married</label>
                    </li>
                    <li class="inputGroup">
                      <input id="radio24" name="radio11" type="radio">
                      <label for="radio24">Divorced</label>
                    </li>
                    <li class="inputGroup">
                      <input id="radio25" name="radio11" type="radio">
                      <label for="radio25">Separated</label>
                    </li>
                    <li class="inputGroup">
                      <input id="radio26" name="radio11" type="radio">
                      <label for="radio26">Other</label>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="item">
                <h3 class="head-qus">Describe your child’s hobbies, interests
                  and special skills.</h3>
                <div class="content-info">
                  <div class="input-div">
                    <textarea id="editor" name="editor"></textarea>
                  </div>
                </div>
              </div>
              <div class="item">
                <h3 class="head-qus">Does your child suffer from one or more of
                  the following?</h3>
                <div class="content-info">
                  <ul class="yes-no-ul">
                    <li class="yes-no-li">
                      <span>Chest asthma</span>
                      <div class="choose">
                        <div>
                          <input id="radio30" name="radio30" type="radio">
                          <label for="radio30">Yes</label>
                        </div>
                        <div>
                          <input id="radio31" name="radio30" type="radio">
                          <label for="radio31">No</label>
                        </div>
                      </div>
                    </li>
                    <li class="yes-no-li">
                      <span>Food Allergies</span>
                      <div class="choose">
                        <div>
                          <input id="radio32" name="radio31" type="radio">
                          <label for="radio32">Yes</label>
                        </div>
                        <div>
                          <input id="radio33" name="radio31" type="radio">
                          <label for="radio33">No</label>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="item">
                <h3 class="head-qus">Current/Previous School Information:</h3>
                <div class="content-info">
                  <div class="input-div">
                    <input type="text">
                    <span class="placeholder">Name of the School</span>
                  </div>
                  <div class="input-div">
                    <textarea name="" id="" rows="3"></textarea>
                    <span class="placeholder">Notes</span>
                  </div>
                  <div class="input-div">
                    <button class="add-btn"><i class="fa fa-plus"></i> Add</button>
                  </div>
                  <div class="table-modal">
                    <table>
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Name of the School</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>1</td>
                          <td>School name</td>
                          <td>
                            <button class="edit-btn"><i class="fa fa-edit"></i> Edit</button>
                            <button class="delete-btn"><i class="fa fa-trash-o"></i>
                              Delete</button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <ul id="carousel-custom-dots" class="owl-dots">
              <li class="owl-dot" aria-label="Please Upload Photo" data-microtip-position="top" role="tooltip">
                <span></span>
              </li>
              <li class="owl-dot" aria-label="Father's Occupation" data-microtip-position="top" role="tooltip">
                <span></span>
              </li>
              <li class="owl-dot" aria-label="Father's Employment Type" data-microtip-position="top" role="tooltip">
                <span></span>
              </li>
              <li class="owl-dot" aria-label="Marital Status of Parents" data-microtip-position="top" role="tooltip">
                <span></span>
              </li>
              <li class="owl-dot" aria-label="Describe your child’s hobbies, interests and special skills"
                data-microtip-position="top" role="tooltip"><span></span></li>
              <li class="owl-dot" aria-label="Does your child suffer from one or more of the following"
                data-microtip-position="top" role="tooltip"><span></span></li>
              <li class="owl-dot" aria-label="Current/Previous School Information" data-microtip-position="top"
                role="tooltip"><span></span></li>
            </ul>
            <div class="btns-carousel">
              <div class="customNextBtn"><i class="fa fa-long-arrow-right"></i>
                Next </div>
              <div class="customPreviousBtn"> Previous <i class="fa fa-long-arrow-left"></i></div>
            </div>
          </div>
        </div> -->
        </div>
        <div class="step-footer">
          <button data-step-action="prev" type="button" class="step-btn prev">{{'Previous'|translate}}</button>
          <button data-step-action="next" type="button" class="step-btn next">{{'Next'|translate}}</button>
          <!--<button data-step-action="finish" (click)="printData()" type="button"
          class="step-btn finish">{{'print'|translate}}</button>-->
          <button data-step-action="finish" (click)="sendData()" type="submit"
            class="step-btn finish">{{'FinishAndPrint'|translate}}</button>
        </div>
      </div>
    </div>
  </div>
</form>

<!-- <div hidden id="resDetails">
  <table>
    <tr>
      <th>aa</th>
      <th>{{PrintedData?.studentData?.name}}</th>
    </tr>
  </table>
</div> -->
<div id="resDetails">
  <table hidden id="resDetails" class="table table-bordered table-hover text-left">
    <thead class="text-center">
      <tr>
        <th colspan="2">
          <img hidden="hidden" id="TableImage" src="" width="15%"/>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr style=" background: #07b2dc;color: white;text-align:center">
        <th colspan="2">{{'studentData'|translate}}</th>
      </tr>
      <tr>
        <th class="w-50">
          {{'FullNameArabic'|translate}}
        </th>
        <th class="w-50">
          {{PrintedData?.studentName}}
        </th>

      </tr>
      <tr>
        <th class="w-50">
          {{'FullNameEnglish'|translate}}
        </th>
        <th class="w-50">
          {{PrintedData?.StudentNameEngilsh}}
        </th>
      </tr>
      <tr>
        <th class="w-50">
          {{'Gender'|translate}}
        </th>
        <th class="w-50">
          {{PrintedData?.studentGender}}
        </th>
      </tr>
      <tr>
        <th class="w-50">
          {{'NationalId'|translate}}
        </th>
        <th class="w-50">
          {{PrintedData?.studentNationalId}}
        </th>
      </tr>
      <tr>
        <th class="w-50">
          {{'DateOfBirth'|translate}}
        </th>
        <th class="w-50">
          {{PrintedData?.StringBirthdate}}
        </th>
      </tr>
      <tr>
        <th class="w-50">
          {{'AgeTillOctober'|translate}}
        </th>
        <th class="w-50">
          {{ageTillOctober?.year}} {{'year'|translate}} {{ageTillOctober?.month}} {{'month'|translate}}
          {{ageTillOctober?.day}} {{'day'|translate}}
        </th>
      </tr>
      <tr>
        <th class="w-50">
          {{'Stage'|translate}}
        </th>
        <th class="w-50">
          {{PrintedData?.PhaseName}}
        </th>
      </tr>
      <tr>
        <th class="w-50">
          {{'Grade'|translate}}
        </th>
        <th class="w-50">
          {{PrintedData?.SubPhaseName}}
        </th>
      </tr>
      <tr>
        <th class="w-50">
          {{'department'|translate}}
        </th>
        <th class="w-50">
          {{PrintedData?.DepartmentName}}
        </th>
      </tr>
      <tr>
        <th class="w-50">
          {{'Address'|translate}}
        </th>
        <th class="w-50">
          {{PrintedData?.StudentHomeAddress}}
        </th>
      </tr>
      <tr>
        <th class="w-50">
          {{'PhoneNumber'|translate}}
        </th>
        <th class="w-50">
          {{PrintedData?.StudentPhoneNumber}}
        </th>
      </tr>
      <tr>
        <th class="w-50">
          {{'Nationality'|translate}}
        </th>
        <th class="w-50">
          {{PrintedData?.StudentNationalityName}}
        </th>
      </tr>
      <tr>
        <th class="w-50">
          {{'Religion'|translate}}
        </th>
        <th class="w-50">
          {{PrintedData?.StudentRelidgionName}}
        </th>
      </tr>
      <tr>
        <th class="w-50">
          {{'lastSchool'|translate}}
        </th>
        <th class="w-50">
          {{PrintedData?.lastSchool}}
        </th>
      </tr>

      <tr>

      </tr>
      <tr style=" background: #07b2dc;color: white;text-align:center">
        <th colspan="2">{{'fatherData'|translate}}</th>
      </tr>
      <tr>
        <th class="w-50">
          {{'FullNameArabic'|translate}}
        </th>
        <th class="w-50">
          {{PrintedData?.fatherName}}
        </th>

      </tr>
      <tr>
        <th class="w-50">
          {{'FullNameEnglish'|translate}}
        </th>
        <th class="w-50">
          {{PrintedData?.fatherNameEngilsh}}
        </th>

      </tr>
      <tr>
        <th class="w-50">
          {{'NationalId'|translate}}
        </th>
        <th class="w-50">
          {{PrintedData?.fatherNationalId}}
        </th>

      </tr>
      <tr>
        <th class="w-50">
          {{'Nationality'|translate}}
        </th>
        <th class="w-50">
          {{PrintedData?.FatherNationalityName}}
        </th>

      </tr>
      <tr>
        <th class="w-50">
          {{'PhoneNumber'|translate}}
        </th>
        <th class="w-50">
          {{PrintedData?.fatherPhoneNumber}}
        </th>

      </tr>
      <tr>
        <th class="w-50">
          {{'Email'|translate}}
        </th>
        <th class="w-50">
          {{PrintedData?.fatherEmail}}
        </th>

      </tr>
      <tr>
        <th class="w-50">
          {{'job'|translate}}
        </th>
        <th class="w-50">
          {{PrintedData?.fatherJob}}
        </th>

      </tr>
      <tr>
        <th class="w-50">
          {{'FatherQualification'|translate}}
        </th>
        <th class="w-50">
          {{PrintedData?.FatherQualification}}
        </th>

      </tr>

      <tr>

      </tr>
      <tr style=" background: #07b2dc;color: white;text-align:center">
        <th colspan="2">{{'MotherData'|translate}}</th>
      </tr>
      <tr>
        <th class="w-50">
          {{'FullNameArabic'|translate}}
        </th>
        <th class="w-50">
          {{PrintedData?.motherName}}
        </th>

      </tr>
      <tr>
        <th class="w-50">
          {{'FullNameEnglish'|translate}}
        </th>
        <th class="w-50">
          {{PrintedData?.motherNameEngilsh}}
        </th>

      </tr>
      <tr>
        <th class="w-50">
          {{'NationalId'|translate}}
        </th>
        <th class="w-50">
          {{PrintedData?.motherNationalId}}
        </th>

      </tr>
      <tr>
        <th class="w-50">
          {{'Nationality'|translate}}
        </th>
        <th class="w-50">
          {{PrintedData?.MotherNationalityName}}
        </th>

      </tr>
      <tr>
        <th class="w-50">
          {{'PhoneNumber'|translate}}
        </th>
        <th class="w-50">
          {{PrintedData?.motherPhoneNumber}}
        </th>

      </tr>
      <tr>
        <th class="w-50">
          {{'Email'|translate}}
        </th>
        <th class="w-50">
          {{PrintedData?.motherEmail}}
        </th>

      </tr>
      <tr>
        <th class="w-50">
          {{'job'|translate}}
        </th>
        <th class="w-50">
          {{PrintedData?.motherJob}}
        </th>

      </tr>
      <tr>
        <th class="w-50">
          {{'MotherQualification'|translate}}
        </th>
        <th class="w-50">
          {{PrintedData?.MotherQualification}}
        </th>

      </tr>
      <tr>
        <th class="w-50">
          {{'SocialStatus'|translate}}
        </th>
        <th class="w-50">
          {{PrintedData?.motherSocialStatusName}}
        </th>

      </tr>
      <tr>
        <th class="w-50">
          {{'legalGardian'|translate}}
        </th>
        <th class="w-50">
          {{PrintedData?.legalGardianName}}
        </th>

      </tr>
    </tbody>
  </table>
</div>