import { Component, OnInit } from '@angular/core';
import { cookieHelper } from 'src/app/core/helpers/cookieHelper';
import { keyValue } from 'src/app/core/models/keyValue';
import { SchoolsSelectService } from 'src/app/core/services/schools-select.service';
import { Router } from "@angular/router"
import { FormBuilder, FormControl } from '@angular/forms';

@Component({
  selector: 'app-schools-select',
  templateUrl: './schools-select.component.html',
  styleUrls: ['./schools-select.component.css']
})


export class SchoolsSelectComponent implements OnInit {
  schools: keyValue[] = [];
  valueFromSelect: any = '';
  schoolId: any = '';
  textFromSelect: any = '';
  // formData = this.formBuilder.group({ ddl_schools: new FormControl() });
  constructor(private schoolsService: SchoolsSelectService, private cookieHelper: cookieHelper, private router: Router, private formBuilder: FormBuilder) {
    debugger;
    this.schoolsService.getSchools().subscribe((schools: any) => {
      this.schoolId = this.cookieHelper.getCookie("schoolid");
      this.schools = schools;
      if (this.schoolId == null || this.schoolId == undefined || this.schoolId == "") {
        this.cookieHelper.setCookie("schoolid", this.schools[0].key.toString(), 1000);
        this.schoolId = this.cookieHelper.getCookie("schoolid");
        this.reload();
      }
      this.textFromSelect = this.schools.find(x => x.key == this.schoolId)?.value.toString();
    });
  }

  ngOnInit(): void {
  }
  ngAfterViewInit(): void {
  }
  clickEvent(selectedValue: any) {
    this.cookieHelper.setCookie("schoolid", selectedValue, 1000);
    this.reload();
  }

  reload() {
    let currentUrl = this.router.url;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([currentUrl]);
  }
}
